const pricingRule = {
  IN: [
    {
      currency: {
        name: "INR",
        symbol: "₹",
      },
      description: "Email within two working days",
      pricePerMonth: 0,
      salesUpperLimit: "20,000",
    },
    {
      currency: {
        name: "INR",
        symbol: "₹",
      },
      description: "Email, Instant Chat, \u0026 Telephonic as soon as possible",
      pricePerMonth: 500,
      salesUpperLimit: "2,00,000",
    },
    {
      currency: {
        name: "INR",
        symbol: "₹",
      },
      description: "Email, Instant Chat, \u0026 Telephonic as soon as possible",
      pricePerMonth: 1000,
      salesUpperLimit: "5,00,000",
    },
    {
      currency: {
        name: "INR",
        symbol: "₹",
      },
      description:
        "Email, Instant Chat, Telephonic \u0026 Account Manager will be assigned",
      pricePerMonth: 2000,
      salesUpperLimit: "15,00,000",
    },
  ],
  US: [
    {
      currency: {
        name: "USD",
        symbol: "$",
      },
      description: "Email within two working days",
      pricePerMonth: 0,
      salesUpperLimit: 1000,
    },
    {
      currency: {
        name: "USD",
        symbol: "$",
      },
      description: "Email, Instant Chat, \u0026 Telephonic as soon as possible",
      pricePerMonth: 20,
      salesUpperLimit: 5000,
    },
    {
      currency: {
        name: "USD",
        symbol: "$",
      },
      description: "Email, Instant Chat, \u0026 Telephonic as soon as possible",
      pricePerMonth: 50,
      salesUpperLimit: "20,000",
    },
    {
      currency: {
        name: "USD",
        symbol: "$",
      },
      description:
        "Email, Instant Chat, Telephonic \u0026 Account Manager will be assigned",
      pricePerMonth: 100,
      salesUpperLimit: "100,000",
    },
  ],
};

const getPricingRule = (countryCode) => {
  return countryCode in pricingRule
    ? pricingRule[countryCode]
    : pricingRule["US"];
};

export default getPricingRule;
