/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

function ImageSlideshow(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [position, setPosition] = useState({ x1: 0, x2: 0 });

  const {
    children,
    changeInterval,
    lightBg,
    arrowAroundContent,
    restrictAutoScroll,
    isDirectionRTL = false,
  } = props;
  const interval = useRef();

  const handleTouchStart = (e) => {
    setPosition({ ...position, x1: e.touches[0].clientX });
  };
  const handleTouchMove = (e) => {
    setPosition({ ...position, x2: e.touches[e.touches.length - 1].clientX });
  };
  const handleTouchEnd = (e) => {
    const { x1, x2 } = position;
    if (x1 && x2) {
      // to avoid nextslide call on single touch
      if (x1 - x2 < 0) {
        previousImage();
      } else {
        nextImage();
      }
    }
    setPosition({ x1: 0, x2: 0 });
  };
  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % children.length);
  };

  const previousImage = () => {
    setCurrentImage(
      (prevImage) =>
        children.length -
        1 -
        ((children.length - 1 - prevImage + 1) % children.length)
    );
  };

  const autoScroll = () => {
    clearInterval(interval.current);
    interval.current = setInterval(nextImage, changeInterval || 3000);
  };

  useEffect(() => {
    if (!restrictAutoScroll) {
      autoScroll();
    }
    return () => {
      clearInterval(interval.current);
    };
  });

  const handleClickLeft = () => {
    autoScroll();
    previousImage();
  };

  const handleClickRight = () => {
    autoScroll();
    nextImage();
  };

  const selectSlide = (index) => {
    autoScroll();
    setCurrentImage(index);
  };

  return (
    <div
      className="image-slideshow"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {arrowAroundContent ? (
        <>
          <div className="content-arrows-wrp">
            {isDirectionRTL ? (
              <RightArrow
                className={`left-arrow-svg ${isDirectionRTL && "rtl-support"}`}
                onClick={handleClickLeft}
                dark={lightBg}
              />
            ) : (
              <LeftArrow
                className="left-arrow-svg"
                onClick={handleClickLeft}
                dark={lightBg}
              />
            )}
            {children.filter((_, index) => index === currentImage)}
            {isDirectionRTL ? (
              <LeftArrow
                className={`right-arrow-svg ${isDirectionRTL && "rtl-support"}`}
                onClick={handleClickRight}
                dark={lightBg}
              />
            ) : (
              <RightArrow
                className="right-arrow-svg"
                onClick={handleClickRight}
                dark={lightBg}
              />
            )}
          </div>
        </>
      ) : (
        <>{children.filter((_, index) => index === currentImage)}</>
      )}

      <div className="dots-container">
        {!arrowAroundContent && (
          <>
            {isDirectionRTL ? (
              <RightArrow
                className={`left-arrow-svg ${isDirectionRTL && "rtl-support"}`}
                onClick={handleClickLeft}
                dark={lightBg}
              />
            ) : (
              <LeftArrow
                className="left-arrow-svg"
                onClick={handleClickLeft}
                dark={lightBg}
              />
            )}
          </>
        )}

        {children.map((img, index) => (
          <div
            key={index}
            onClick={() => selectSlide(index)}
            className={index === currentImage ? "selected-dot" : "dots"}
          />
        ))}
        {!arrowAroundContent && (
          <>
            {isDirectionRTL ? (
              <LeftArrow
                className={`right-arrow-svg ${isDirectionRTL && "rtl-support"}`}
                onClick={handleClickRight}
                dark={lightBg}
              />
            ) : (
              <RightArrow
                className="right-arrow-svg"
                onClick={handleClickRight}
                dark={lightBg}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ImageSlideshow;
