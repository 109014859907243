import React from "react";

function PricingSvg(props) {
  return (
    <svg
      width="76px"
      height="17px"
      viewBox="0 0 76 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        id="pricing-page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Currency-change-web"
          transform="translate(-682, -903)"
          stroke="#B8B8B5"
        >
          <g id="Group-22" transform="translate(682, 903)">
            <circle id="Oval-Copy-5" cx="68" cy="8" r="7.5"></circle>
            <circle id="Oval-Copy-6" cx="8" cy="8" r="7.5"></circle>
            <path
              d="M8.74726634,8.36360239 C12.624699,12.7878675 22.5422769,15 38.5,15 C54.4577231,15 64.2910565,12.3333333 68,7"
              id="Path-39-Copy"
              stroke-width="3"
              stroke-linecap="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PricingSvg;
