/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { navigate } from "@reach/router";
import Link from "gatsby-link";
import React, { Component } from "react";
import Helmet from "react-helmet";
import EazyHeader from "../../components/Header";
import LayoutWrapper from "../../components/Layout";
import Popup from "../../components/Popup";
import SignupForm from "../../components/SignupForm";
import SlideShow from "../../components/SlideShow";
import WithProviders from "../../components/WithProviders";
import Arrow from "../../images/arrow.svg";
import FeatureCross from "../../images/Cross.svg";
import DukaanLogo from "../../images/dukaan.png";
import FeatureTick from "../../images/Green_tick.svg";
import indiaFlag from "../../images/india.svg";
import globeIconWhite from "../../images/Language-icon-white.svg";
import globeIcon from "../../images/LanguageIcon.svg";
import Minus from "../../images/Minus.svg";
import Plus from "../../images/Plus.svg";
import PricingBanner1 from "../../images/pricing-banner-new-1.svg";
import PricingBanner2 from "../../images/pricing-banner-new-2.svg";
import PricingBanner3 from "../../images/pricing-banner-new-3.svg";
import ShopifyLogo from "../../images/shopify.png";
import supportImage from "../../images/support_image.webp";
import TickIcon from "../../images/tick-icon.svg";
import Tick from "../../images/tick.webp";
import { Message } from "../../lib/getMessages";
import getPricingRule from "../../lib/getPricingRule";
import fetchTimeout from "../../utils/fetchTimeout";
import getFormattedPrice from "../../utils/getFormattedPrice";
import "./pricing.css";
import PricingSvg from "./PricingSvg";

const IPDataUrl = "https://ipapi.co/json/";
const FrequentlyAskedQuestions = [
  {
    question: <Message dictKey="what.is.activation.charge.question" />,
    answer: (
      <p>
        <Message dictKey="what.is.activation.charge.answer" />
      </p>
    ),
  },
  {
    question: <Message dictKey="what.happens.free.trail.end.question" />,
    answer: (
      <div>
        <p>
          <Message dictKey="what.happens.free.trail.end.answer" />
        </p>
      </div>
    ),
  },
  {
    question: (
      <Message dictKey="need.maintenance.charge.running.store.question" />
    ),
    answer: (
      <p>
        <Message dictKey="need.maintenance.charge.running.store.answer" />
      </p>
    ),
  },
  {
    question: <Message dictKey="how.do.pay.charge.question" />,
    answer: (
      <p>
        <Message dictKey="how.do.pay.charge.answer" />
      </p>
    ),
  },
  {
    question: (
      <Message dictKey="how.do.pay.monthly.maintenance.charge.question" />
    ),
    answer: (
      <p>
        <Message dictKey="how.do.pay.monthly.maintenance.charge.answer1" />
        <a
          href="https://zopping.com/admin/user/account-balance"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Message dictKey="account.wallet" />
        </a>
        <Message dictKey="how.do.pay.monthly.maintenance.charge.answer2" />
      </p>
    ),
  },

  {
    question: <Message dictKey="my.account.balance.insufficient.question" />,
    answer: (
      <div className="page-gap">
        <p>
          <Message dictKey="my.account.balance.insufficient.answer" />
        </p>
        <p>
          <Message dictKey="my.account.balance.insufficient.answer.case1" />
        </p>
        <p>
          <Message dictKey="my.account.balance.insufficient.answer.case2" />
        </p>
      </div>
    ),
  },
  {
    question: <Message dictKey="need.to.bear.in.mind.question" />,
    answer: (
      <div className="page-gap">
        <p>
          <Message dictKey="need.to.bear.in.mind.answer1" />
        </p>
        <p>
          <Message dictKey="need.to.bear.in.mind.answer2" />
        </p>
        <p>
          <Message dictKey="need.to.bear.in.mind.answer3" />
        </p>
      </div>
    ),
  },
];

const currencyData = [
  {
    id: 1,
    currencyCode: "INR",
    name: "India",
    src: indiaFlag,
    countryCode: "IN",
  },
  {
    id: 2,
    currencyCode: "USD",
    name: "Other Countries",
    src: globeIcon,
    countryCode: "US",
  },
];

const bannerFeaturesList = [
  {
    id: 1,
    image: PricingBanner1,
    desc: "feature.one.new.description",
  },
  {
    id: 2,
    image: PricingBanner2,
    desc: "feature.two.new.description",
  },
  {
    id: 3,
    image: PricingBanner3,
    desc: "feature.three.new.description",
  },
];

const highlightList = [
  {
    id: 1,
    desc: <Message dictKey="highlight.hosting" />,
  },
  {
    id: 2,
    desc: <Message dictKey="highlight.themes" />,
  },
  {
    id: 3,
    desc: <Message dictKey="highlight.product.listing" />,
  },
  {
    id: 4,
    desc: <Message dictKey="highlight.unlimited.users" />,
  },
  {
    id: 5,
    desc: <Message dictKey="highlight.unlimited.operations" />,
  },
  {
    id: 6,
    desc: <Message dictKey="highlight.apps" />,
  },
  {
    id: 7,
    desc: <Message dictKey="highlight.extensions" />,
  },
  {
    id: 8,
    desc: <Message dictKey="highlight.gateway.integrations" />,
  },
  {
    id: 9,
    desc: <Message dictKey="highlight.delivery.partner" />,
  },
  {
    id: 10,
    desc: <Message dictKey="highlight.social.media" />,
  },
  {
    id: 11,
    desc: <Message dictKey="highlight.customer.support" />,
  },
];
const features = [
  {
    onlineStore: [
      { featureKey: "hostingSsl" },
      { featureKey: "themes" },
      { featureKey: "customerSignup" },
      { featureKey: "multiStore" },
      { featureKey: "multiSales" },
    ],
  },
  {
    catalogue: [
      { featureKey: "products" },
      { featureKey: "collectionTags" },
      { featureKey: "weightBasedPrices" },
      { featureKey: "productSubstitution" },
      { featureKey: "stockManagement" },
      { featureKey: "rackManagement" },
    ],
  },
  {
    payments: [
      { featureKey: "gatewayInteg" },
      { featureKey: "walletCashback" },
      { featureKey: "cashOnDelivery" },
      { featureKey: "voucherGifts" },
    ],
  },
  {
    marketingOffers: [
      { featureKey: "campaigns" },
      { featureKey: "notifications" },
      { featureKey: "customSeo" },
      { featureKey: "coupons" },
      { featureKey: "offers" },
    ],
  },
  {
    orderManagement: [
      { featureKey: "manualOrder" },
      { featureKey: "pickerApp" },
      { featureKey: "intelligentPicking" },
    ],
  },
  {
    deliveryManagement: [
      { featureKey: "surcharge" },
      { featureKey: "slotCharges" },
      { featureKey: "vehiclePlanning" },
      { featureKey: "routePlanning" },
      { featureKey: "deliveryPersonTracking" },
      { featureKey: "thirdPartyLogistics" },
    ],
  },
  {
    staffManagement: [
      { featureKey: "multiUsers" },
      { featureKey: "employeeMobileApp" },
      { featureKey: "attendanceSummary" },
      { featureKey: "roleBasedPermissions" },
    ],
  },
  {
    reportAnalytics: [{ featureKey: "reports" }, { featureKey: "analytics" }],
  },
  // {
  //   pricing: [
  //     { featureKey: "monthlyFixedCharges" },
  //     { featureKey: "commissions" },
  //     { featureKey: "freePlugins" },
  //     { featureKey: "customerMobileApp" },
  //   ],
  // },
];

const ComparisonTableData = {
  zopping: {
    img: "https://storage.googleapis.com/zopping-uploads/3585/images/originals/20240102/logo-20240102-070752.webp",
    featuresData: {
      onlineStore: {
        hostingSsl: "yes",
        themes: "customizable",
        customerSignup: "customizable",
        multiStore: "yes",
        multiSales: "yes",
      },
      catalogue: {
        products: "unlimited",
        collectionTags: "yes",
        weightBasedPrices: "yes",
        productSubstitution: "yes",
        stockManagement: "yes",
        rackManagement: "yes",
      },
      payments: {
        gatewayInteg: "yes",
        walletCashback: "yes",
        cashOnDelivery: "yes",
        voucherGifts: "yes",
      },
      marketingOffers: {
        campaigns: "yes",
        notifications: "free",
        customSeo: "yes",
        coupons: "unlimited",
        offers: "yes",
      },
      orderManagement: {
        manualOrder: "yes",
        pickerApp: "yes",
        intelligentPicking: "yes",
      },
      deliveryManagement: {
        surcharge: "yes",
        slotCharges: "yes",
        vehiclePlanning: "yes",
        routePlanning: "yes",
        deliveryPersonTracking: "yes",
        thirdPartyLogistics: "yes",
      },
      staffManagement: {
        multiUsers: "yes",
        employeeMobileApp: "yes",
        attendanceSummary: "yes",
        roleBasedPermissions: "yes",
      },
      reportAnalytics: {
        reports: "customizable",
        analytics: "yes",
      },
      pricing: {
        monthlyFixedCharges: "no",
        commissions: "Less than 0.25%",
        freePlugins: "yes",
        customerMobileApp: "Chargeable",
      },
    },
  },
  shopify: {
    img: ShopifyLogo,
    featuresData: {
      onlineStore: {
        hostingSsl: "yes",
        themes: "customizable",
        customerSignup: "standard",
        multiStore: "yes",
        multiSales: "yes",
      },
      catalogue: {
        products: "unlimited",
        collectionTags: "yes",
        weightBasedPrices: "no",
        productSubstitution: "no",
        stockManagement: "yes",
        rackManagement: "no",
      },
      payments: {
        gatewayInteg: "no",
        walletCashback: "no",
        cashOnDelivery: "yes",
        voucherGifts: "yes",
      },
      marketingOffers: {
        campaigns: "yes",
        notifications: "no",
        customSeo: "yes",
        coupons: "limited",
        offers: "yes",
      },
      orderManagement: {
        manualOrder: "yes",
        pickerApp: "no",
        intelligentPicking: "no",
      },
      deliveryManagement: {
        surcharge: "no",
        slotCharges: "no",
        vehiclePlanning: "no",
        routePlanning: "no",
        deliveryPersonTracking: "no",
        thirdPartyLogistics: "yes",
      },
      staffManagement: {
        multiUsers: "yes",
        employeeMobileApp: "no",
        attendanceSummary: "no",
        roleBasedPermissions: "yes",
      },
      reportAnalytics: {
        reports: "standard",
        analytics: "yes",
      },
      pricing: {
        monthlyFixedCharges: "yes",
        commissions: "Less than 2%",
        freePlugins: "no",
        customerMobileApp: "no",
      },
    },
  },
  dukaan: {
    img: DukaanLogo,
    featuresData: {
      onlineStore: {
        hostingSsl: "yes",
        themes: "standard",
        customerSignup: "standard",
        multiStore: "no",
        multiSales: "no",
      },
      catalogue: {
        products: "limited",
        collectionTags: "yes",
        weightBasedPrices: "yes",
        productSubstitution: "no",
        stockManagement: "yes",
        rackManagement: "no",
      },
      payments: {
        gatewayInteg: "no",
        walletCashback: "yes",
        cashOnDelivery: "yes",
        voucherGifts: "no",
      },
      marketingOffers: {
        campaigns: "yes",
        notifications: "premium",
        customSeo: "no",
        coupons: "limited",
        offers: "no",
      },
      orderManagement: {
        manualOrder: "yes",
        pickerApp: "no",
        intelligentPicking: "no",
      },
      deliveryManagement: {
        surcharge: "no",
        slotCharges: "no",
        vehiclePlanning: "no",
        routePlanning: "no",
        deliveryPersonTracking: "no",
        thirdPartyLogistics: "yes",
      },
      staffManagement: {
        multiUsers: "no",
        employeeMobileApp: "no",
        attendanceSummary: "no",
        roleBasedPermissions: "no",
      },
      reportAnalytics: {
        reports: "standard",
        analytics: "yes",
      },
      pricing: {
        monthlyFixedCharges: "yes",
        commissions: "0%",
        freePlugins: "yes",
        customerMobileApp: "premium",
      },
    },
  },
};
class Faq extends Component {
  checkLinkClick = (e) => {
    if (e.target.tagName !== "A") {
      this.props.onToggle();
    }
  };
  render() {
    const { expanded, question, answer, isDirectionRTL } = this.props;
    let containerStyle =
      !expanded || this.contentRef
        ? {
            maxHeight: expanded ? this.contentRef.offsetHeight + "px" : 0,
          }
        : {};
    return (
      <div
        className={`faq ${expanded ? "expanded" : ""}`.trim()}
        onClick={this.checkLinkClick}
      >
        <div className="question">
          <h4>{question}</h4>
          <div className={`${expanded ? "up-arrow" : "down-arrow"}`.trim()}>
            &nbsp;
          </div>
        </div>
        <div className="content-container" style={containerStyle}>
          <div
            className={`answer${isDirectionRTL ? "-rtl" : ""} muted`}
            ref={(node) => {
              this.contentRef = node;
            }}
          >
            <div>{answer}</div>
          </div>
        </div>
      </div>
    );
  }
}

function PricingCard(props) {
  let {
    price,
    sale,
    currency,
    prevSales,
    selectedCurrency,
    index,
    isDirectionRTL,
  } = props;
  const formatCurrency = (amount) => {
    switch (selectedCurrency) {
      case "USD":
        return getFormattedPrice(amount.replace(/,/g, ""));
      default:
        return amount;
    }
  };
  let salesUpperLimit = prevSales?.salesUpperLimit.toLocaleString();
  sale = sale.toLocaleString();
  let currencySymbol;
  if (price !== "FREE") currencySymbol = currency.symbol;
  else currencySymbol = "";
  let priceRange;
  if (
    (currency.symbol === "₹" && sale === "20,000") ||
    (currency.symbol === "$" && sale === "1,000")
  ) {
    priceRange = "Less than " + currencySymbol + " " + formatCurrency(sale);
  } else {
    priceRange = isDirectionRTL
      ? currencySymbol +
        " " +
        formatCurrency(sale) +
        " to " +
        currencySymbol +
        " " +
        formatCurrency(salesUpperLimit)
      : currencySymbol +
        " " +
        formatCurrency(salesUpperLimit) +
        " to " +
        currencySymbol +
        " " +
        formatCurrency(sale);
  }
  return (
    <>
      {index === 0 && (
        <div className="highlight-pricing">
          <p className="price-rule">
            <span>
              <Message dictKey="pricing.rule" />
            </span>
          </p>
          <p className="charge-per-month">
            <Message dictKey="charges.per.month" />
          </p>
        </div>
      )}
      <div className="highlight-pricing">
        <p
          className={`muted highlight-price ${
            isDirectionRTL && "highlight-price-rtl"
          }`}
        >
          <span>{`${priceRange}`}</span>
        </p>
        <p
          className={`charge-price ${isDirectionRTL && "rtl-support"}`}
        >{` ${currencySymbol} ${price}`}</p>
      </div>
    </>
  );
}

const getPricingCards = (pricingRule, selectedCurrency, isDirectionRTL) => {
  return pricingRule.map((pricing, index) => (
    <PricingCard
      price={pricing.pricePerMonth}
      sale={pricing.salesUpperLimit}
      desc={pricing.description}
      key={pricing.pricePerMonth}
      currency={pricing.currency}
      pricingRule={pricingRule}
      prevSales={pricingRule[index - 1]}
      selectedCurrency={selectedCurrency}
      index={index}
      isDirectionRTL={isDirectionRTL}
    />
  ));
};
const getTrailPricing = (selectedCurrency) => {
  switch (selectedCurrency) {
    case "USD":
      return "$ 69";
    default:
      return "₹ 4999";
  }
};
const getTrailPricingStrike = (selectedCurrency) => {
  switch (selectedCurrency) {
    case "USD":
      return "$ 149";
    default:
      return "₹ 9999";
  }
};
const getHighlightList = (isDirectionRTL) => {
  return highlightList.map((highlight) => (
    <li key={highlight.id}>
      <img src={TickIcon} alt="" className="highlight-tick" />
      <span className={`highlight-desc ${isDirectionRTL && "rtl-support"}`}>
        {highlight.desc}
      </span>
    </li>
  ));
};

const getBannerFeatures = (isDirectionRTL) => {
  return bannerFeaturesList.map((feature) => (
    <div
      className={`banner-feature-desc ${isDirectionRTL && "rtl-support"}`}
      key={feature.id}
    >
      <div className="banner-img-wrp">
        <img className="banner-feature-img" src={feature.image} alt="" />
      </div>
      <Message dictKey={feature.desc} />
    </div>
  ));
};

const LanguageDropdown = ({
  dropDownToggle,
  selectedCurrency,
  selectedFlag,
  showDropDown,
  handleSelect,
  addMobileClass,
  isDirectionRTL,
}) => {
  let currSelectedFlag = selectedFlag;
  if (selectedCurrency === "USD") {
    currSelectedFlag = globeIconWhite;
  }
  return (
    <div
      className={[
        "wrapper-dropdown",
        addMobileClass ? "wrapper-dropdown-mobile" : "",
      ].join(" ")}
      onClick={dropDownToggle}
    >
      <input
        name="txtContent"
        type="text"
        id="txtContent"
        className={`currency-value ${isDirectionRTL && "rtl-currency-value"}`}
        placeholder=""
        value={`${selectedCurrency}`}
        readOnly
      />
      <img
        src={currSelectedFlag}
        alt="selected-flag"
        className="selected-flag"
      />
      {showDropDown && (
        <ul className={`dropdown ${isDirectionRTL && "rtl-support"}`}>
          <div>
            {currencyData.map(
              ({ id, name, src, currencyCode, countryCode }) => (
                <li
                  key={id}
                  onClick={(e) => {
                    handleSelect(countryCode);
                  }}
                >
                  <img src={src} alt="" />
                  <span>{`${name} (${currencyCode})`}</span>
                </li>
              )
            )}
          </div>
        </ul>
      )}
    </div>
  );
};
class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedIndex: null,
      showDropDown: false,
      selectedCurrency: getPricingRule(this.props.countryCode)[0]?.currency,
      selectedFlag:
        currencyData.filter(
          (cd) => cd.countryCode === this.props.countryCode
        )[0]?.src || currencyData[1].src,
      pricingRule: getPricingRule(this.props.countryCode),
      comparisonSelected: "shopify",
      showComparisonSelector: false,
      showComparisonDropdown: [true],
      showSignupModal: false,
      isLoggedin: false,
    };
    this.getFaqs = this.getFaqs.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleIp = () => {
    const controller = new AbortController();
    try {
      fetchTimeout(IPDataUrl, 5000, {
        signal: controller.signal,
      }).then((data) => {
        data
          .json()
          .then((readableData) => {
            const countryIsoCode = readableData?.country_code;
            if (countryIsoCode !== "IN") {
              this.handleSelect("US");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    if (window.localStorage.getItem("token")) {
      this.setState({ isLoggedin: true });
    }
    this.setState(
      {
        selectedCurrency: getPricingRule(this.props.countryCode)[0]?.currency
          .name,
        selectedFlag:
          currencyData.filter(
            (cd) => cd.countryCode === this.props.countryCode
          )[0]?.src || currencyData[1].src,
        pricingRule: getPricingRule(this.props.countryCode),
      },
      this.handleIp()
    );
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
  toggle(index) {
    this.setState((prevState) => {
      let expandedIndex = prevState.expandedIndex === index ? null : index;
      return {
        expandedIndex,
      };
    });
  }
  dropDownToggle = () => {
    this.setState((prevState) => {
      return {
        showDropDown: !prevState.showDropDown,
      };
    });
  };
  comparisonDropdownToggle = (index) => {
    this.setState((prevState) => {
      let comparisonDropdown = [...prevState.showComparisonDropdown];
      comparisonDropdown[index] = !comparisonDropdown[index];
      return {
        showComparisonDropdown: comparisonDropdown,
      };
    });
  };
  getFaqs(isDirectionRTL) {
    let _this = this;
    let expandedIndex = this.state.expandedIndex;
    let faqs = FrequentlyAskedQuestions.map((faq, index) => {
      return (
        <Faq
          key={`faq-${index}`}
          onToggle={() => _this.toggle(index)}
          expanded={expandedIndex === index}
          question={faq.question}
          answer={faq.answer}
          isDirectionRTL={isDirectionRTL}
        />
      );
    });
    return faqs;
  }
  setCompetitorSelectorRef = (node) => {
    this.competitorRef = node;
  };
  handleClickOutside = (event) => {
    if (this.competitorRef && !this.competitorRef.contains(event.target)) {
      this.setState({ showComparisonSelector: false });
    }
  };

  handleSelect = (countryCode) => {
    this.props.updateCountryCode(countryCode);
    this.setState({
      selectedCurrency: getPricingRule(countryCode)[0]?.currency.name,
      selectedFlag:
        currencyData.filter((cd) => cd.countryCode === countryCode)[0]?.src ||
        currencyData[1].src,
      pricingRule: getPricingRule(countryCode),
    });
  };
  toggleComparisonSelector = () => {
    this.setState((prevState) => ({
      showComparisonSelector: !prevState.showComparisonSelector,
    }));
  };
  handleClickSelectCompetitor = (competitor) => {
    this.setState({ comparisonSelected: competitor });
  };
  handleClickShowSignupForm = () => {
    this.setState((prevState) => ({
      showSignupModal: !prevState.showSignupModal,
    }));
  };
  setIsLoggedIn = (isLoggedin) => {
    this.setState({ isLoggedin });
  };
  handleSelectStoreForFreeClick = () => {
    if (!this.state.isLoggedin) {
      this.handleClickShowSignupForm();
    } else {
      window.open("/admin/home/dashboard", "_blank");
    }
  };

  render() {
    const { language, updateLanguage, direction } = this.props;
    const lang = language ? `/${language}` : "";
    let canonicalUrl = `https://staging.zopping.com/whyus`;
    if (typeof window !== "undefined") {
      if (window.location.host === "zopping.com") {
        canonicalUrl = `https://zopping.com/whyus`;
      }
    }
    const isDirectionRTL = direction === "rtl";
    return (
      <LayoutWrapper language={language} location={this.props.location}>
        <div className="eazy-page">
          <Helmet title="Zopping - Why Us">
            <meta
              name="description"
              content="Get started for free and pay as you go. No credit card required."
            />
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
          <EazyHeader
            language={language}
            updateLanguage={updateLanguage}
            current="whyus"
            hiddenHeader={this.state.hiddenHeader}
            isDirectionRTL={isDirectionRTL}
          />
          <div className="eazy-pricing">
            <section className="overview tc">
              <section className="brief mask">
                <div className="currency-container">
                  <div>
                    <h1>
                      <Message dictKey="why.choose.us" />
                    </h1>
                    {/* <p>
                      <Message dictKey="pay.for.use" />
                    </p> */}
                  </div>
                  <div className="banner-feature-wrp">
                    {getBannerFeatures(isDirectionRTL)}
                  </div>
                  <div className="mobile-slideshow">
                    <SlideShow isDirectionRTL={isDirectionRTL}>
                      {bannerFeaturesList.map((feature) => (
                        <div
                          className="banner-feature-wrp-mobile"
                          key={feature.id}
                        >
                          <div
                            className={`banner-feature-desc ${
                              isDirectionRTL && "rtl-support"
                            }`}
                          >
                            <div className="banner-img-wrp">
                              <img
                                className="banner-feature-img"
                                src={feature.image}
                                alt=""
                              />
                            </div>
                            <Message dictKey={feature.desc} />
                          </div>
                        </div>
                      ))}
                    </SlideShow>
                  </div>
                </div>
              </section>
            </section>
            {/* <section className="price-compare-desc">
              <div className="price-compare-table-wrp">
                <div className="highlight-wrp">
                  <div className="highlight-header-wrp">
                    <LanguageDropdown
                      dropDownToggle={this.dropDownToggle}
                      selectedCurrency={this.state.selectedCurrency}
                      selectedFlag={this.state.selectedFlag}
                      showDropDown={this.state.showDropDown}
                      handleSelect={this.handleSelect}
                      addMobileClass
                      isDirectionRTL={isDirectionRTL}
                    />

                    <div className="table-header-wrp">
                      <p>
                        <Message dictKey="activation.charge" />
                      </p>
                      <div className="price-and-lang-wrp">
                        <span className="monthly-charge-desktop">
                          <Message dictKey="monthly.charges" />
                        </span>
                        <LanguageDropdown
                          dropDownToggle={this.dropDownToggle}
                          selectedCurrency={this.state.selectedCurrency}
                          selectedFlag={this.state.selectedFlag}
                          showDropDown={this.state.showDropDown}
                          handleSelect={this.handleSelect}
                          isDirectionRTL={isDirectionRTL}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pricing-contect">
                    <div className="highlight-table-wrp">
                      <div className="pay">
                        <Message dictKey="pay" />
                        <div className="pay-strike">
                          {getTrailPricingStrike(this.state.selectedCurrency)}
                        </div>
                      </div>
                      <div
                        className={`currency ${
                          isDirectionRTL && "rtl-support"
                        }`}
                      >
                        {getTrailPricing(this.state.selectedCurrency)}
                        <span
                          className={`inclusive-taxes ${
                            isDirectionRTL && "rtl-support"
                          }`}
                        >
                          (<Message dictKey="inclusive.all.taxes" />)
                        </span>
                      </div>
                      <div className="pay-once">
                        <Message dictKey="pay.once" />
                      </div>
                      <div className="pay-once">
                        <Message dictKey="use.forever" />
                      </div>
                      <div className="free-trail">
                        <Message dictKey="free.trail" />
                      </div>
                      <PricingSvg
                        className={`conecting-svg-top ${
                          isDirectionRTL && "rtl-support"
                        }`}
                      />

                      <PricingSvg
                        className={`conecting-svg-bottom ${
                          isDirectionRTL && "rtl-support"
                        }`}
                      />
                    </div>
                    <span className="month-charge-mobile">
                      <Message dictKey="monthly.charges" />
                    </span>
                    <div className="highlight-table-wrp">
                      {getPricingCards(
                        this.state.pricingRule,
                        this.state.selectedCurrency,
                        isDirectionRTL
                      )}
                    </div>
                  </div>
                </div>
                <div className="pricing-highlights">
                  <p className="highlight-header">
                    <Message dictKey="highlight.heading" />
                  </p>
                  <ul className="features-list">
                    <div className="heighlight-point">
                      {getHighlightList(isDirectionRTL)}
                    </div>
                  </ul>
                </div>
                <div
                  className="action"
                  onClick={this.handleSelectStoreForFreeClick}
                >
                  <Message dictKey="setup.store.free" />
                </div>
                <Popup
                  show={this.state.showSignupModal}
                  close={this.handleClickShowSignupForm}
                >
                  <SignupForm
                    close={this.handleClickShowSignupForm}
                    showLogin={this.handleClickShowSignupForm}
                    setIsLoggedIn={this.setIsLoggedIn}
                  />
                </Popup>
                <span className="highlight-charges-wrp">
                  <Message dictKey="addtional.charges" />
                </span>
              </div>
            </section> */}
            <section></section>
            <section className="advanced-features">
              <div className="customization">
                <div className="customization-image">
                  <img
                    src={supportImage}
                    alt="support"
                    className="support-image"
                  />
                </div>

                <div
                  className={`customization-button ${
                    isDirectionRTL && "rtl-support"
                  }`}
                >
                  <div className="header tc">
                    <h2>
                      <Message dictKey="customization.new.integration" />
                    </h2>
                    {/* <p className="muted">
                      <Message dictKey="more.than" />
                      <span>
                        {this.state.pricingRule.length &&
                          `${this.state.pricingRule[0].currency.symbol}${
                            this.state.pricingRule[
                              this.state.pricingRule.length - 1
                            ].salesUpperLimit
                          }/-`}
                      </span>
                      <Message dictKey="month.sales" />
                    </p> */}
                  </div>
                  <Link to={`${lang}/contact`}>
                    <div className="contact-us-submit">
                      <Message dictKey="contact.us.new.support" />
                    </div>
                  </Link>
                </div>
              </div>
            </section>

            <section
              className="pricing-comparison"
              id="pricing-comparison-container"
            >
              <div className="pricing-comparison-heading-data-wrp">
                <h2 className="pricing-comparison-heading">
                  <Message dictKey="pricing.comparison.subheading" />
                </h2>
                {/* <p className="pricing-comparison-subheading">
                  <Message dictKey="pricing.comparison.subheading" />
                </p> */}
                <hr />
                <div className="pricing-comparison-wrp">
                  <div
                    className={`pricing-comparison-header ${
                      isDirectionRTL && "rtl-support"
                    }`}
                  >
                    <div
                      className={`pricing-features-heading ${
                        isDirectionRTL && "rtl-support"
                      }`}
                    >
                      <Message dictKey="features" />
                    </div>
                    <div className="pricing-features-image">
                      <img
                        className="zopping-img"
                        src={ComparisonTableData.zopping.img}
                        alt=""
                      />
                    </div>
                    <div className="pricing-features-image">
                      <div
                        className="img-wrp icon-light-bg competitor-wrp"
                        onClick={this.toggleComparisonSelector}
                        ref={this.setCompetitorSelectorRef}
                      >
                        <img
                          className="competitor-img others"
                          alt=""
                          src={
                            ComparisonTableData[this.state.comparisonSelected]
                              .img
                          }
                        />
                        <div className="arrow-wrp">
                          <img
                            alt=""
                            className={
                              this.state.showComparisonSelector
                                ? "dropdown-open"
                                : ""
                            }
                            src={Arrow}
                          />
                        </div>
                        {this.state.showComparisonSelector && (
                          <div className="comparison-selector-dropdown">
                            {Object.keys(ComparisonTableData)
                              .filter((competitor) => competitor !== "zopping")
                              .map((competitor) => (
                                <div
                                  className={`competitors ${
                                    this.state.comparisonSelected === competitor
                                      ? "selected"
                                      : ""
                                  }`}
                                  key={competitor}
                                  onClick={() =>
                                    this.handleClickSelectCompetitor(competitor)
                                  }
                                >
                                  <img
                                    className="competitor-img others"
                                    src={ComparisonTableData[competitor].img}
                                    alt=""
                                  />
                                  {this.state.comparisonSelected ===
                                    competitor && (
                                    <img
                                      className="tick"
                                      src={Tick}
                                      alt="selected"
                                    />
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="pricing-comparison-container">
                    {features.map((categoryName, index) => {
                      const parentCategory = Object.keys(categoryName)[0];
                      return (
                        <div
                          className={[
                            "pricing-comparison-row",
                            !this.state.showComparisonDropdown[index]
                              ? "pc-show-padding-bottom"
                              : "",
                            isDirectionRTL && "rtl-support",
                          ].join(" ")}
                          key={`${categoryName}${index}`}
                        >
                          <div
                            className={`category-icon-wrp ${
                              isDirectionRTL && "rtl-support"
                            }`}
                            onClick={() => this.comparisonDropdownToggle(index)}
                          >
                            <img
                              alt=""
                              src={
                                this.state.showComparisonDropdown[index]
                                  ? Minus
                                  : Plus
                              }
                            />
                            <div>
                              <Message dictKey={parentCategory} />
                            </div>
                          </div>
                          {this.state.showComparisonDropdown[index] && (
                            <div
                              className={`feature-comparison-desc ${
                                isDirectionRTL && "rtl-support"
                              }`}
                            >
                              <div className="dropdown-wrp">
                                {Object.values(categoryName)[0].map(
                                  ({ featureKey }) => {
                                    const featureStatusZopping =
                                      ComparisonTableData.zopping.featuresData[
                                        parentCategory
                                      ][featureKey];
                                    const featureStatusOther =
                                      ComparisonTableData[
                                        this.state.comparisonSelected
                                      ].featuresData[parentCategory][
                                        featureKey
                                      ];
                                    return (
                                      <div
                                        className="feature-row"
                                        key={featureKey}
                                      >
                                        <div className="feature-name">
                                          <Message dictKey={featureKey} />
                                        </div>
                                        <div
                                          className={`feature-status ${
                                            isDirectionRTL && "rtl-support"
                                          }`}
                                        >
                                          {featureStatusZopping === "yes" ? (
                                            <img src={FeatureTick} alt="Yes" />
                                          ) : featureStatusZopping === "no" ? (
                                            <img src={FeatureCross} alt="No" />
                                          ) : (
                                            <Message
                                              dictKey={featureStatusZopping}
                                            />
                                          )}
                                        </div>
                                        <div
                                          className={`feature-status ${
                                            isDirectionRTL && "rtl-support"
                                          }`}
                                        >
                                          {featureStatusOther === "yes" ? (
                                            <img src={FeatureTick} alt="Yes" />
                                          ) : featureStatusOther === "no" ? (
                                            <img src={FeatureCross} alt="No" />
                                          ) : (
                                            <Message
                                              dictKey={featureStatusOther}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="faq-section">
              <div className="header tc">
                <h2>
                  <Message dictKey="pricing.faq" />
                </h2>
                <hr />
              </div>
              <section className="faqs">{this.getFaqs(isDirectionRTL)}</section>
              <div className="contact-us">
                <p className="muted">
                  <Message dictKey="cant.find.answer" />
                </p>
                <Link to={`${lang}/contact`}>
                  <div className="contact-us-submit">
                    <Message dictKey="contact.us.support" />
                  </div>
                </Link>
              </div>
            </section> */}
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default WithProviders(Pricing);
